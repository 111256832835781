import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheSolution = () => {
	return (
		<Container fluid style={{ background: 'rgb(247, 246, 249)' }}>
			<Container className="carousel-columns pt-5 ">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">The Solution</h6>
					</div>
				</div>
				<h2>GTEN TEST AUTOMATION SOLUTION- GAINS</h2>
			</Container>
			<Container className="pb-5">
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							We provide deep experience in cross-platform testing for mobile applications for both functional and non-functional (Manual & Automated) testing. GTEN’s Mobile Testing Center of Excellence (TCoE) constitutes certified testers in commercial & open-source mobile testing tools, our test architects have 15+ years of experience inmobile application testing for various domains such as Banking, Insurance, Travel, Retail.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<div className="initial-paragrapgh">
							Some of the challenges for mobile testing solved by our test automation platform GAINS are:
						</div>
						<ul>
							<li className="text-list">Integration with all leading mobile cloud device labs for running multi-platform, network, local and OS tests.</li>
							<li className="text-list">Integration with Xcode and Android SDK to get logs and debugging information for any application issue leading to faster resolutions.</li>
							<li className="text-list">Lack of pre-built automated test suites and a team of scripting experts.</li>
							<li className="text-list">Migration challenges in while modernizing legacy automation scripts.</li>
							<li className="text-list">Lack of expertise in automating regression tests.</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default TheSolution;