import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheDifferences = () => {
	return (
		<Container fluid className="mt-5" style={{ background: 'rgb(247, 246, 249)' }}>
			<Container className="carousel-columns pt-5">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">Our Differences</h6>
					</div>
				</div>
				<h2>GTEN’s Mobile Testing CoE provides access to a wide range of testing services, mobile testing tools, and mobile device lab.</h2>
				<Row>
					<Col>
						<div className="initial-paragrapgh">
							The key differentiators of GTEN’s Mobile Testing CoE are:

						</div>
						<ul>
							<li className="text-list">Partnerships with world’s leading mobile testing tools providers for cost-effective tool procurement.</li>
							<li className="text-list">Dedicated test environments for Platform Certification Testing.</li>
							<li className="text-list">GAINS platform which provides self-service reporting dashboards with advanced analytics.</li>
							<li className="text-list">Access to cloud based scalable and secure mobile app testing device lab with 600+ mobile devices across different platforms such as iOS and Android.</li>
							<li className="text-list">We follow a mind map-based approach for mobile application test planningfor maximum coverage on device specific functionality tests.</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</Container>
	);
};

export default TheDifferences;