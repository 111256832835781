import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import MobileTestingContainer from '../containers/MobileTestingContainer'

const MobileTesting = (props) => (
	<Layout
		pageInfo={{ pageName: "Mobile Testing" }}
		styleClass="home-page "
		location={props.location.pathname}>
		<SEO title="Mobile Testing" />
		<MobileTestingContainer />
	</Layout>
)

export default MobileTesting
