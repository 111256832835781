import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
const TheOfferings = () => {
	return (
		<>
			<Container className="carousel-columns mt-5">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">Our Offering</h6>
					</div>
				</div>
				<h2>END-TO-END MOBILE TESTING SERVICES</h2>
			</Container>
			<Container>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							GTEN Technologies can help you navigate through the most difficult digital challenges with our expertise in Test Automation services.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<div className="initial-paragrapgh">
							Our Services and tool expertise include:

						</div>
						<ul>
							<li className="text-list">Mobile Test Advisory Services.</li>
							<li className="text-list">Mobile Test Tool Feasibility.</li>
							<li className="text-list">Mobile Test Automation Framework Development.</li>
							<li className="text-list">Interrupt testing – Voice, SMS, Notifications interrupts.</li>
							<li className="text-list">Memory leak and consumption validation.</li>
							<li className="text-list">App performance Validation including CPU, Network usage, page rendering time.</li>
							<li className="text-list">Installation testing including new install, uninstall and reinstall, upgrade testing.</li>
							<li className="text-list">Battery and Geo-fence validations.</li>
						</ul>
					</Col>
				</Row>
			</Container>
		</>
	);
};

export default TheOfferings;