import React, { Component } from 'react'
import BannerImage from '../components/elements/BannerImage'
import banner from '../images/slicing/service/banner.png'
import CaseStudyComponent from '../components/services/CaseStudyComponent'
import TestimonialComponent from '../components/services/TestimonialComponent'
import TheSolution from '../components/mobiletesting/TheSolution'
import TheChallenges from '../components/mobiletesting/TheChallenges'
import TheOfferings from '../components/mobiletesting/TheOfferings'
import TheDifferences from '../components/mobiletesting/TheDifferences'
class MobileTestingContainer extends Component {
	render() {
		return (
			<>
				<BannerImage
					activeTab="Mobile & Cross-Platform Testing Services"
					bannerImage={banner}
					styling="long-heading"
					text="Reliable Mobile Testing to meet customer expectations" />
				<TheChallenges />
				<TheSolution />
				<TheOfferings />
				<TheDifferences />
				<CaseStudyComponent />
				<TestimonialComponent />
			</>
		)
	}
}

export default MobileTestingContainer