import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'

const TheChallenges = () => {
	return (
		<Container fluid>
			<Container className="carousel-columns pt-5 ">
				<div className="d-flex justify-content-start align-items-center">
					<div className="line"></div>
					<div>
						<h6 className="carousel-section-subheading">The Challenge</h6>
					</div>
				</div>
			</Container>
			<Container className="pb-5">
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh">
							According to a recent industry survey 51% respondents are working towards adding new capabilities for testing IoT and smart devices. Companies even after making substantial investments in mobile application testing are struggling to meet the growing demands and complexity with multiple device manufacturers, operating system and network speeds.
						</p>
					</Col>
				</Row>
				<Row className="pt-2 px-3">
					<Col>
						<p className="initial-paragrapgh text-muted">
							With 80%+ customers and millennials even more using their smartphones for work, pleasure and shopping. It has become mission critical for enterprises to continuously innovate and launch frictionless experience on all mobile platforms.
						</p>
					</Col>
				</Row>
			</Container>
		</Container>
	)
}

export default TheChallenges